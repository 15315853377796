window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
import { Datepicker } from 'vanillajs-datepicker';
import ru from 'vanillajs-datepicker/locales/ru';
import Swal from "sweetalert2";


const loader = `<?xml version="1.0" encoding="utf-8"?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<g transform="rotate(0 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(30 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(60 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(90 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(120 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(150 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(180 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(210 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(240 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(270 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(300 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(330 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
  </rect>
</g></svg>`;
document.addEventListener("DOMContentLoaded", function() {
    const datepicker = document.querySelectorAll('.date-picker');
    Object.assign(Datepicker.locales, ru);

    Array.from(datepicker).forEach(item => {
        const options = Object.assign({language: 'ru',weekStart:1, orientation: 'bottom', autohide:true},  item.dataset.options ? JSON.parse(item.dataset.options) : {});
        const datepicker = new Datepicker(item, options);
    });

   
    window.axios.defaults.headers.common[
        "X-CSRF-TOKEN"
    ] = document
        .querySelector('meta[name="csrf-token"')
        .getAttribute("content");
    const forms = document.querySelectorAll(".form");
    Array.from(forms).forEach(form => {
        const button = form.querySelector(".submit");
        const buttonText = button.innerHTML;
        button.addEventListener("click", e => {
            e.preventDefault();
            Array.from(document.querySelectorAll('.inp-err-message')).forEach(item => item.remove());
            grecaptcha.ready(() => {
                grecaptcha
                    .execute(button.dataset.sitekey, {
                        action: "submit"
                    })
                    .then(token => {
                        button.innerHTML = loader;
                        button.disabled = true;
                        const formdata = new FormData(form);
                        axios
                            .post(form.getAttribute("action"), formdata)
                            .then(response => {
                                button.innerHTML = buttonText;
                                button.disabled = false;
                                Array.from(form.querySelectorAll('input')).forEach(item => item.value = '');

                                Swal.fire({
                                    title: "Спасибо! Ваша заявка принята!",
                                    text: "Наш менеджер свяжеться с вами.",
                                    icon: "success",
                                    confirmButtonText: "Отлично!"
                                });
                            })
                            .catch(error => {
                                Array.from(document.querySelectorAll('.inp-err-message')).forEach(item => item.remove());
                                button.innerHTML = buttonText;
                                button.disabled = false;
                                console.log(error);
                                if(error.response.status == 422) {
                                    const errors = error.response.data;
                                    const keys = Object.keys(errors);
                                    for(let key of keys) {
                                        const elem = form.querySelector(`[name="${key}"]`);
                                        const block = elem.parentNode;
                                        block.classList.add('inp-error');
                                        const errmessage = document.createElement("div");
                                        errmessage.classList.add('inp-err-message');
                                        errmessage.classList.add('text-sm');
                                        errmessage.classList.add('text-red-500');
                                        errmessage.innerText = errors[key];
                                        block.appendChild(errmessage)
                                    }
                                }
                            });
                    });
            });
        });
    });
});
